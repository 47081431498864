import Link from "next/link";
import { GetStaticProps } from "next";
import React, { useState, Fragment, useEffect, Suspense } from 'react'
import { PostContent, fetchPostContent } from "../lib/posts";
import FeaturedPosts from '../components/home/FeaturedPosts';
import MediaCoverage from '../components/home/MediaCoverage';
import UsersSay from '../components/home/UsersSay'
import Partners from '../components/Partners';
import Head from 'next/head'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import { toggleComingSoon } from '../store/slices/modalSlice'
import { useAppDispatch } from "../hooks/home";
import Image from "next/image";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";


type Props = {
  // message: String,
  posts: PostContent[]
};

// import required modules
import { Pagination, Navigation, Mousewheel, Keyboard, Autoplay } from "swiper";
const LandingPageModal = React.lazy(() => import("../components/LandingPageModal"));

export default function Index({ posts }: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useAppDispatch()



  useEffect(() => {
    let firstTime = sessionStorage.getItem("first_time");
    if (!firstTime) {
      sessionStorage.setItem("first_time", "1");
      setIsOpen(true)
    }
  }, [])




  const closeModalOtp = () => {
    sessionStorage.setItem("first_time", "2");
    setIsOpen(false)
    PauseVideo()
  }
  function PauseVideo() {
    let targetDiv = document.getElementById("Q8qQHVi2SZg")
    // @ts-ignore
    for (var i = 0, childNode; i <= targetDiv?.childNodes.length; i++) {
      childNode = targetDiv?.childNodes[i];
      if (/bar/.test(childNode.className)) {
        childNode.innerHTML = "Goodbye world!";
      }
    }
  }



  return (
    <>
      <Head>
        <title>Eonmed - #1 Digital Healthcare Platform in India</title>
        <meta name="keywords" content="EONmed" />
        <meta name="description" content="Eonmed provides various services to ease the process of receiving high-quality treatments and medical solutions in a virtual system." />
        <meta name="facebook-domain-verification" content="sy0ok7yak8394pelnc0zjzc7wsxh6g" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `(
              {
                "@context": "https://schema.org",
                "@type": "MedicalOrganization",
                "name": "Eonmed",
                "alternateName": "Eonmed India",
                "url": "https://eonmed.in",
                "logo": "https://eonmed.in/images/logo1.webp",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "telephone": "0120-311-7850",
                  "contactType": "customer service",
                  "contactOption": "TollFree",
                  "areaServed": "IN",
                  "availableLanguage": ["en","Hindi"]
                },
                "sameAs": [
                  "https://www.facebook.com/EonmedIndia",
                  "https://twitter.com/Eonmedindia",
                  "https://www.instagram.com/eonmedindia",
                  "https://www.youtube.com/channel/UC9UIYTb9i1Uvn5Yk2ZGNNQw",
                  "https://www.linkedin.com/company/eonmed",
                  "https://in.pinterest.com/eonmedindia",
                  "https://eonmed.in"
                ]
              }              
  );`,
          }}
        ></script>
      </Head>

      {/* <h1>{message}</h1>
      <h1>{posts.length}</h1> */}
      <>
        <Swiper cssMode={true}
          //autoplay
          pagination={true}
          mousewheel={true}
          keyboard={true}
          autoplay={{
            delay: 7000
          }}
          modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]} className="mySwiper">
          <SwiperSlide><Link href="https://user.eonmed.in/"><a>
            <div className="h-40 sm:h-80 w-100">
              <Image src="/images/banner1.webp" alt="banner1" height={300} width={300} layout="fill" />
            </div>
          </a></Link></SwiperSlide>
          {/* <SwiperSlide><Link href="/healthcare-corporate-camps"><a><img src="images/banner2.webp" className="d-block h-40 sm:h-full w-100" alt="..." /></a></Link></SwiperSlide> */}
          <SwiperSlide><Link href="/healthcare-corporate-camps"><a>
            <div className="h-40 sm:h-80 w-100">
              <Image src="/images/banner3.webp" alt="banner3" height={300} width={300} layout="fill" />
            </div>

          </a></Link></SwiperSlide>
          <SwiperSlide><Link href="/school-healthcare-camps"><a>
            <div className="h-40 sm:h-80 w-100">
              <Image src="/images/banner4.webp" alt="banner4" height={300} width={300} layout="fill" />
            </div>
          </a></Link></SwiperSlide>
          {/* <SwiperSlide><Link href=""><a><img src="images/banner5.webp" className="d-block w-100" alt="..." /></a></Link></SwiperSlide> */}
          <SwiperSlide><Link href="/smart-clinic"><a>
            <div className="h-40 sm:h-80 w-100">
              <Image src="/images/banner6.webp" alt="banner6" height={300} width={300} layout="fill" />
            </div>
          </a></Link></SwiperSlide>
          <SwiperSlide><Link href="/healthcare-corporate-camps"><a>
            <div className="h-40 sm:h-80 w-100">
              <Image src="/images/banner7.webp" alt="banner7" height={300} width={300} layout="fill" />
            </div>
          </a></Link></SwiperSlide>
        </Swiper>



        <section className="mt-5 max-w-fit px-3 sm:max-w-7xl sm:px-0 mx-auto">
          <div className="container">


            <h3 className="text-base md:text-2xl mb-2">Products</h3>



            <div className="grid grid-cols-2 md:grid-cols-4 gap-x-4">
              <div>
                <div className="lightBlueBox borderRadius customBorder">
                  <Link href="/primary-health-care"><a>
                    <div className="imageHolder borderRadius">
                      <Image src="/images/primaryHealthCare3.webp" alt="Primary Health Care" width={300}
                        height={180} objectFit="contain" objectPosition="bottom bottom" layout="intrinsic" />
                    </div></a>
                  </Link>
                  <div className="px-3 pb-2">
                    <div className="flex justify-between flex-col sm:flex-row">
                      <div>
                        <div className="heading tracking-normal lg:tracking-tighter xl:tracking-tighter 2xl:tracking-normal flex justify-start">Primary<br /> Health Care</div>
                      </div>
                      <div>
                        <Link href="/primary-health-care"><a className="border-1 p-2 bg-[#5EB3E4] text-white hover:bg-[#005B77] rounded-md text-center flex justify-center mt-1 md:mt-3">Order Now</a></Link>
                      </div>
                    </div>
                  </div>
                </div>

              </div>


              <div>
                <div className="lightOrangeBox borderRadius customBorder">
                  <Link href="/doctor-consultancy"><a><div className="imageHolder borderRadius">
                    <Image src="/images/doctorConsultant.webp" alt="Doctor Consultancy" width={300}
                      height={180} objectFit="contain" objectPosition="bottom bottom" />

                  </div></a></Link>
                  <div className="px-3 pb-2">
                    <div className="flex justify-between flex-col sm:flex-row">
                      <div>
                        <div className="heading flex justify-start">Doctor<br /> Consultancy</div>
                      </div>
                      <div>
                        <Link href="/doctor-consultancy"><a className="border-1 p-2 bg-[#03B4EA] text-white hover:bg-[#474747] rounded-md text-center flex justify-center mt-1 md:mt-3">Consult Now</a></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>

                <div className="lightGreenBox borderRadius customBorder">
                  <Link href="/covid-care-plus"><a><div className="imageHolder borderRadius">
                    <Image src="/images/covidPlus.webp" alt="Covid Care Plus" width={300}
                      height={180} objectFit="contain" objectPosition="bottom bottom" />
                  </div></a>
                  </Link>
                  <div className="px-3 pb-2">
                    <div className="flex justify-between flex-col sm:flex-row">
                      <div>
                        <div className="heading flex justify-start">Covid Care<br /> Plus</div>
                      </div>
                      <div>
                        <Link href="/covid-care-plus"><a className="border-1 p-2 bg-[#3BBFAD] text-white hover:bg-[#184D46] rounded-md text-center flex justify-center mt-1 md:mt-3">Order Now</a></Link>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div>

                <div className="lightPurpleBox borderRadius customBorder">
                  <a onClick={() => dispatch(toggleComingSoon())} ><div className="imageHolder borderRadius cursor-pointer">
                    <Image src="/images/angcard.webp" alt="Covid Self Test" width={300}
                      height={180} objectFit="contain" objectPosition="bottom bottom" />
                  </div></a>
                  <div className="px-3 pb-2">
                    <div className="flex justify-between flex-col sm:flex-row">
                      <div>
                        <div className="heading tracking-wide flex justify-start">Covid Self<br /> Test</div>
                      </div>
                      <div>
                        <a onClick={() => dispatch(toggleComingSoon())} className="border-1 p-2 bg-[#494DB5] text-white hover:bg-[#151745] rounded-md text-center flex justify-center mt-1 md:mt-3 cursor-pointer">Order Now</a>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>

          </div>

        </section>

        <section className="py-5 mt-1 max-w-fit px-3 sm:max-w-7xl sm:px-0 mx-auto">

          <div className="container">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">

              <div>

                <div className="borderRadius customBorder halfBox">
                  <div className="grid grid-cols-1 sm:grid-cols-2">
                    <div className="p-2 order-2 sm:order-1">
                      <div className="heading mb-2">Smart Clinic</div>
                      <div className="flex sm:flex-col">
                        <p className="mb-2 basis-3/5">A Virtual Clinic Is A Web-Based Meeting Center Where Healthcare Providers Can Interact With Patients Instantly. Our...</p>
                        <Link href="/smart-clinic"><a className="blueButtonStyle borderRadius7 basis-2/5 mr-1 h-fit sm:w-fit self-center sm:self-auto"><span className="flex justify-center">Read More</span></a></Link>
                      </div>
                    </div>
                    <div className="order-1 sm:order-2">
                      <Image src="/images/virtual-clinic.webp" alt="Virtual Clinic" width={300}
                        height={180} layout="responsive" objectPosition="bottom bottom" />
                    </div>
                  </div>
                </div>

              </div>

              <div>

                <div className="borderRadius customBorder halfBox">
                  <div className="grid grid-cols-1 sm:grid-cols-2">
                    <div className="p-2 order-2 sm:order-1">
                      <div className="heading mb-2">DOCQR</div>
                      <div className="flex sm:flex-col">
                        <p className="mb-2 basis-3/5">This QR Code Is A One-Stop Solution For The Patients To Receive Medical Solutions In An Easy And Timely Manner. These...</p>
                        <Link href="/docqr"><a className="blueButtonStyle borderRadius7 basis-2/5 mr-1 h-fit sm:w-fit self-center sm:self-auto"><span className="flex justify-center">Read More</span></a></Link>
                      </div>
                    </div>
                    <div className="order-1 sm:order-2">
                      <Image src="/images/qrcode.webp" alt="Qr Code" width={300}
                        height={180} layout="responsive" objectPosition="bottom bottom" />
                    </div>
                  </div>
                </div>

              </div>

            </div>

          </div>

        </section>



        <section className="services py-5 mt-2">
          <div className="container max-w-fit px-3 sm:max-w-7xl sm:px-0 mx-auto">

            <h3 className="text-base md:text-2xl">Our Initiatives</h3>


            <div className="grid grid-cols-1 sm:grid-cols-4 space-x-0 md:space-x-2 space-y-3">
              <div className="mt-2 mb-2">
                <p className="text-xs md:text-sm">EONMED provides various services to ease the process of receiving high-quality treatments and medical solutions in a virtual system. The main aim of EONMED is to reduce the waiting time for the patients in getting their medical queries resolved.</p>
              </div>



              <div className="serviceBox bg-white borderRadius customBorder p-3">
                <Image src="/images/healthcare-corporate-camps.webp" alt="Healthcare Corporate Camps" width={300}
                  height={220} layout="responsive" objectPosition="bottom bottom" />
                <div className="flex justify-between mt-2 items-center">
                  <div>
                    <div className="heading tracking-tighter">Healthcare<br /> Corporate Camps</div>
                  </div>
                  <div>
                    <Link href="healthcare-corporate-camps"><a className="blueButtonStyle borderRadius7">Know More</a></Link>
                  </div>
                </div>
              </div>

              <div className="serviceBox bg-white borderRadius customBorder p-3">
                <Image src="/images/healthcare-school-camps.webp" alt="Healthcare School Camps" width={300}
                  height={220} layout="responsive" objectPosition="bottom bottom" />
                <div className="flex justify-between mt-2 items-center">
                  <div>
                    <div className="heading">Healthcare<br /> School Camps</div>
                  </div>
                  <div>
                    <Link href="/school-healthcare-camps"><a className="blueButtonStyle borderRadius7">Know More</a></Link>
                  </div>
                </div>
              </div>

              <div className="serviceBox bg-white borderRadius customBorder p-3">
                <Image src="/images/mass-covid-testing.webp" alt="Mass Covid Testing" width={300}
                  height={220} layout="responsive" objectPosition="bottom bottom" />
                <div className="flex justify-between mt-2 items-center">
                  <div>
                    <div className="heading">Mass<br /> Covid Testing</div>
                  </div>
                  <div>
                    <Link href="/mass-covid-test"><a className="blueButtonStyle borderRadius7">Know More</a></Link>
                  </div>
                </div>
              </div>


            </div>
          </div>

        </section>

        <div className="max-w-fit px-3 sm:max-w-7xl sm:px-0 mx-auto">
          <FeaturedPosts posts={posts} />
        </div>

        <section id="journey" className="journey py-5">

          <div className="container text-center">

            <h3 className="text-base md:text-2xl">Journey So Far</h3>

            <div className="grid grid-cols-1 sm:grid-cols-2">

              <div className="pb-12">
                <div className="flex justify-center">
                  <Image src="/assets/50k.svg" alt="Doctor consultation Completed" width={180}
                    height={150} objectFit="contain" objectPosition="bottom bottom" />
                  <span className="statsStyle mt-5">50<em>K+</em></span>
                </div>
                <h4 className="mt-4 text-base md:text-xl">Doctor Consultation Completed</h4>

              </div>

              <div className="pb-12">
                <div className="flex justify-center">
                  <Image src="/images/10k.svg" alt="COVID Self Test Completed" width={180}
                    height={150} objectFit="contain" objectPosition="bottom bottom" />
                  <span className="statsStyle mt-5">10<em>K+</em></span>
                </div>
                <h4 className="mt-4 text-base md:text-xl">COVID Self-Test Completed</h4>

              </div>

            </div>

          </div>



        </section>


        <section className="max-w-fit px-3 sm:max-w-7xl sm:px-0 mx-auto">
          <MediaCoverage />
        </section>

        <section className="max-w-fit px-3 sm:max-w-7xl sm:px-0 mx-auto mt-3">
          <UsersSay />
        </section>



        <section id="testimonials" className="py-5 max-w-fit px-3 lg:max-w-5xl xl:max-w-6xl 2xl:max-w-7xl sm:px-0 mx-auto mt-2">
          <div className="container text-center">
            <div className="border px-4 pt-10 borderRadius">
              {/* <h4 className="mb-5 text-base md:text-2xl">What our users have to say</h4> */}
              <div className="testimonialBox">
                <div>
                  <div className="w-2/3 md:w-full md:max-w-3xl mx-auto">
                    <p>A few months back,I was in another town for work when I started having mild symptoms. That’s when my friend suggested that I take covid care plus kit. And trust me when I say, it didn’t feel like I wasn’t at home. With the covid care plus kit, it was easy to get back to health and routine quickly and test negative soon with the help of all the things provided in this kit and constant medical attention by expert doctors.</p>
                    <h5 className="flex justify-center items-center space-x-2 mt-2">
                      <Image src="/images/dummyicon.svg" alt="User" width={25}
                        height={25} objectFit="contain" objectPosition="bottom bottom" />
                      <span className="text-xs md:text-lg">Mohan | Primary Health Care</span></h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Partners />

      </>

      {isOpen &&
        <Suspense fallback={<div>Loading...</div>}>
          <LandingPageModal {...{ isOpen, closeModalOtp }} />
        </Suspense>
      }

    </>
  );
}

export const getStaticProps: GetStaticProps = async ({ params }) => {
  const posts = fetchPostContent();
  return {
    props: {
      posts: posts
    },
    revalidate: 60,

  }
}
