import { PostContent } from "../lib/posts";
import Link from "next/link";
import Image from "next/image";
type Props = {
  post: PostContent;
};
export default function PostItem({ post }: Props) {
  return (
    <Link href={"/blog/" + post.slug}>
      <div className="relative bg-gray-50 pt-1 pb-2 px-2 sm:px-3 lg:pt-2 lg:pb-5 lg:px-4">
        <div className="relative max-w-7xl mx-auto">
          <div className="mt-2 max-w-lg mx-auto grid grid-cols-1 lg:max-w-none">
            <div key={post.title} className="flex flex-col rounded-lg outline outline-1 outline-[#c7c7c7] overflow-hidden xl:h-[530px]">
              <div className="flex-shrink-0">
                <div className="h-48 w-full">
                  <Image src={post.heroImage} alt={post.title} objectFit='cover' height={250} width={500} layout="intrinsic" />
                </div>
                {/* <img className="h-48 w-full object-cover" src={post.heroImage} alt={post.title} /> */}
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <a href={"/posts/" + post.slug} className="block mt-2">
                    <p className="text-base md:text-xl font-semibold text-gray-900">{post.title}</p>
                    <p className="mt-3 text-base text-gray-500">{post.shortDescription}</p>
                  </a>
                </div>
                <div className="mt-6 flex items-center">
                  <div className="flex-shrink-0">
                    <a href={post.fullPath}>
                      <span className="sr-only">{post.author}</span>
                      <div className="h-10 w-10 rounded-full">
                        <Image src={post.heroImage} className="rounded-full" alt="" objectFit="cover" height={50} width={50} layout="intrinsic" />
                      </div>
                      {/* <img className="h-10 w-10 rounded-full" src={post.heroImage} alt="" /> */}
                    </a>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">
                      <a href={post.fullPath} className="hover:underline">
                        {post.author}
                      </a>
                    </p>
                    <div className="flex space-x-1 text-sm text-gray-500">
                      <time dateTime={post.date}>{post.date}</time>
                      <span aria-hidden="true">&middot;</span>

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </Link>


  );
}
