import PostItem from "../PostItem";
import { PostContent } from "../../lib/posts";

// // Import Swiper styles
// import "swiper/css";
// import "swiper/css/navigation";


type Props = {
  posts: PostContent[];
  // tags: TagContent[];

};
export default function Index({ posts }: Props) {

  return (
    <>
      <section className="blogs my-5">
        <div className="container">
          <div>
            <h3 className="text-base md:text-2xl ml-2 mb-2">Our Latest Articles</h3>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3">
            {posts.slice(0, 3).map((it, i) => (

              <PostItem key={i} post={it} />

            ))}

          </div>

        </div>
      </section>
    </>
  );
}

