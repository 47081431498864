import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Mousewheel, Keyboard } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

const UsersSay = () => {
    return (
        <div className='users'>
            <h1 className='text-center my-5 text-base md:text-2xl mb-4'>What our users have to say</h1>
            <Swiper cssMode={true}
                pagination={true} mousewheel={true}
                keyboard={true} modules={[Pagination]} spaceBetween={30}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    480: {
                        slidesPerView: 2,
                    },
                    640: {
                        slidesPerView: 4,
                    },
                }}
                className="mySwiper mt-5">
                <SwiperSlide>
                    <LiteYouTubeEmbed
                        id="2J_ot4fGI8g"
                        title="1"
                        adNetwork={true}
                        params=""
                        playlist={false}
                        aspectHeight={15}
                    />

                </SwiperSlide>
                <SwiperSlide>
                    <LiteYouTubeEmbed
                        id="gB6ifao_Njc"
                        title="2"
                        adNetwork={true}
                        params=""
                        playlist={false}
                        aspectHeight={15}
                    />

                </SwiperSlide>
                <SwiperSlide>
                    <LiteYouTubeEmbed
                        id="h_qLt_WSg2A"
                        title="3"
                        adNetwork={true}
                        params=""
                        playlist={false}
                        aspectHeight={15}
                    />


                </SwiperSlide>
                <SwiperSlide>
                    <LiteYouTubeEmbed
                        id="4aTxDeujh0w"
                        title="4"
                        adNetwork={true}
                        params=""
                        playlist={false}
                        aspectHeight={15}
                    />


                </SwiperSlide>
                <SwiperSlide>
                    <LiteYouTubeEmbed
                        id="9cehlMdmmkc"
                        title="5"
                        adNetwork={true}
                        params=""
                        playlist={false}
                        aspectHeight={15}
                    />

                </SwiperSlide>
                <SwiperSlide>
                <LiteYouTubeEmbed
                        id="QtntahwR5CU"
                        title="6"
                        adNetwork={true}
                        params=""
                        playlist={false}
                        aspectHeight={15}
                    />
                    

                </SwiperSlide >
                <SwiperSlide>
                <LiteYouTubeEmbed
                        id="wpsGeMyuISA"
                        title="7"
                        adNetwork={true}
                        params=""
                        playlist={false}
                        aspectHeight={15}
                    />
                    

                </SwiperSlide>
                <SwiperSlide>
                <LiteYouTubeEmbed
                        id="byN6OE_YSKQ"
                        title="8"
                        adNetwork={true}
                        params=""
                        playlist={false}
                        aspectHeight={15}
                    />
                   

                </SwiperSlide>
                <SwiperSlide>
                <LiteYouTubeEmbed
                        id="RL6GDD3GWTA"
                        title="9"
                        adNetwork={true}
                        params=""
                        playlist={false}
                        aspectHeight={15}
                    />
                   
                </SwiperSlide>
                <SwiperSlide>
                <LiteYouTubeEmbed
                        id="bP43eaW4Zi0"
                        title="10"
                        adNetwork={true}
                        params=""
                        playlist={false}
                        aspectHeight={15}
                    />
                    

                </SwiperSlide>
                <SwiperSlide>
                <LiteYouTubeEmbed
                        id="CQdg3Sf3ujw"
                        title="11"
                        adNetwork={true}
                        params=""
                        playlist={false}
                        aspectHeight={15}
                    />
                    
                </SwiperSlide>
                <SwiperSlide>
                <LiteYouTubeEmbed
                        id="e07vHMOaTSM"
                        title="12"
                        adNetwork={true}
                        params=""
                        playlist={false}
                        aspectHeight={15}
                    />

                </SwiperSlide>
                <SwiperSlide>
                <LiteYouTubeEmbed
                        id="yWUrh1OtC9A"
                        title="13"
                        adNetwork={true}
                        params=""
                        playlist={false}
                        aspectHeight={15}
                    />


                </SwiperSlide>
            </Swiper >
        </div >
    )
}

export default UsersSay