import { Swiper, SwiperSlide } from 'swiper/react';
import Head from 'next/head'
import Script from 'next/script'
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import Image from 'next/image';
// import required modules
import { Pagination, Navigation, Mousewheel, Keyboard, Autoplay } from "swiper";


export default function Index() {

    const data = ["client1", "client2", "client3", "client4", "client5", "client6"]
    return (
        <>
            <section id="partners">
                <h3 className="text-center mb-5 mt-2 text-base md:text-2xl">Partners and Associations</h3>
                <div className="lightBg position-relative">

                    <div className="container grid grid-cols-2 sm:grid-cols-6 max-w-fit px-3 sm:max-w-7xl sm:px-0 mx-auto">
                        {data.map((c,i) => (
                            <Image key={i} src={`/images/${c}.png`} alt={c} width={150}
                                height={120} objectFit="contain" objectPosition="bottom bottom" />
                        ))}

                    </div>
                </div>
            </section>      </>
    );
}
